.contact-section {
    padding: 2rem;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 5rem;
    margin-bottom: 1rem;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.8rem;
    font-size: 1rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: grey;
    outline: none;
  }
  
  .submit-btn {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    color: #fff;
    background: gray;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background: darkgrey;
  }
  
  .status-message {
    margin-top: 1rem;
    font-size: 1rem;
    color: green;
  }
  
  .status-message.error {
    color: red;
  }
  


  .services-list {
    font-size: 1.8rem;
    margin-top: 10px;
  }